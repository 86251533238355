import React, { useState } from "react";
// import { graphql, useStaticQuery } from "gatsby";
import mail from "../images/mail.svg";

const ContactFormWithMap = () => {
  // const { site } = useStaticQuery(graphql`
  //     query {
  //         site {
  //         siteMetadata {
  //             title
  //             contact {
  //             mail
  //             phone
  //             address
  //             }
  //         }
  //         }
  //     }
  //     `);

  const initialFormState = {
    $nombre: "",
    $email: "",
    $telefono: "",
    $mensaje: "",
    subject: "[Feedback IT] Nuevo mensaje",
    honeypot: "", // if any value received in this field, form submission will be ignored.
    replyTo: "@", // this will set replyTo of email to email address entered in the form
    accessKey: "be416949-fbd3-45c7-a727-069f9871eb53", // get your access key from https://www.staticforms.xyz
  };

  const [dataForm, setDataForm] = useState(initialFormState);

  const [response, setResponse] = useState({
    type: "",
    message: "",
  });

  const [error, setError] = useState(false);

  const handleChange = e => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const { $nombre, $email, $telefono, $mensaje } = dataForm;

  const isEmail = email => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(email).toLowerCase());
  };

  const handleSubmit = async e => {
    e.preventDefault();

    //Validar
    if ($nombre.trim() === "" || $telefono.trim() === "" || $mensaje.trim() === "" || !isEmail($email)) {
      setError(true);
      return;
    }

    setError(false);

    const dataFormWithReplyTo = { ...dataForm, replyTo: dataForm.$email };

    try {
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(dataFormWithReplyTo),
        headers: { "Content-Type": "application/json" },
      });

      const json = await res.json();

      if (json.success) {
        setResponse({
          type: "success",
          message: "Mensaje enviado con éxito, le responderemos a la brevedad.",
        });

        //Reiniciar el formulario
        setDataForm(initialFormState);
      } else {
        setResponse({
          type: "error",
          message: json.message,
        });
      }
    } catch (e) {
      console.log("Ocurrio un error", e);
      setResponse({
        type: "error",
        message: "Ocurrio un error al enviar el mensaje.",
      });
    }
  };
  return (
    <>
      <section id="contactForm" className="text-gray-700 body-font bg-white py-10">
        <div className="flex justify-center items-center">
          <form
            action="https://api.staticforms.xyz/submit"
            method="post"
            className="w-full md:w-4/6 bg-white flex flex-col md:py-8 p-10 shadow-lg mt-8 md:mt-0"
            name="contacto"
            onSubmit={handleSubmit}
          >
            {/* Options staticforms */}
            <div className="control">
              <input type="text" name="honeypot" style={{ display: "none" }} onChange={handleChange} />
              <input type="hidden" name="subject" onChange={handleChange} />
            </div>

            {/* Options staticforms */}
            <div className="flex flex-row justify-start items-center">
              <img src={mail} className="md:-ml-16 md:absolute mr-4 mb-2 md:m-0 h-12 w-12 " />
              <h2 className="text-gray-900 text-lg font-bold">ESCRIBINOS</h2>
            </div>
            <p className="leading-relaxed mb-5 text-gray-600">
              Envianos tu consulta, responderemos a la brevedad
            </p>

            <div className="flex flex-col lg:flex-row">
              <input
                onChange={handleChange}
                name="$nombre"
                value={$nombre}
                className="w-full lg:w-1/2 bg-white rounded border border-gray-400 focus:outline-none focus:border-blue-600 text-base px-4 py-2 mb-4 lg:mr-2"
                placeholder="Nombre"
                type="text"
              />
              <input
                onChange={handleChange}
                name="$telefono"
                value={$telefono}
                className="w-full lg:w-1/2 bg-white rounded border border-gray-400 focus:outline-none focus:border-blue-600 text-base px-4 py-2 mb-4 lg:ml-2"
                placeholder="Teléfono"
                type="number"
              />
            </div>
            <input
              onChange={handleChange}
              name="$email"
              value={$email}
              className="w-full bg-white rounded border border-gray-400 focus:outline-none focus:border-blue-600 text-base px-4 py-2 mb-4"
              placeholder="Mail"
              type="email"
            />
            <textarea
              onChange={handleChange}
              name="$mensaje"
              value={$mensaje}
              className="w-full bg-white rounded border border-gray-400 focus:outline-none h-32 focus:border-blue-600 text-base px-4 py-2 mb-4 resize-none"
              placeholder="Mensaje"
            />
            {error ? (
              <p className="text-red-600 ">
                Todos los campos son obligatorios, por favor verifique que los datos introducidos sean
                válidos.
              </p>
            ) : null}
            {response.message ? (
              <p className="text-green-600 ">{response.message}</p>
            ) : (
              <p className="text-red-600 ">{response.message}</p>
            )}
            {/* <p>{response.message}</p> */}
            <button
              className="text-white bg-blue-600 border-0 py-2 px-6 focus:outline-none hover:bg-blue-700 rounded text-lg"
              type="submit"
            >
              Enviar
            </button>
            <p className="text-xs text-gray-500 mt-3">Gracias por confiar en nosotros.</p>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactFormWithMap;
