import React from "react";

import Layout from "../components/layout";
import Hero from "../components/Hero";
import SectionDivisor from "../components/SectionDivisor";
import ContainerSection from "../components/ContainerSection";
// import Phrase from "../components/Phrase";

import SectionTitle from "../components/SectionTitle";
// import ContactFormWithMap from '../components/ContactFormWithMap';
import ContactForm from "../components/ContactForm";

import imageHero from "../images/contacto.svg";
// import mapImage from "../images/map.svg";
import ubicaciones from "../images/ubicaciones.svg";
// import tipitosMundo from '../images/tipitosmundo.svg'

import Location from "../components/Location";

function Contacto() {
  return (
    <Layout>
      <Hero
        title={"QUEREMOS AYUDARTE"}
        // subtitle={'Envianos tu consulta!'}
        ctaUrl={"/contacto#contactForm"}
        cta={"ESCRIBINOS"}
        image={imageHero}
      />

      <SectionDivisor type={1} />

      <ContainerSection type={1}>
        <SectionTitle title={"CONTACTO"} />

        {/* Formulario */}
        {/* <ContactFormWithMap/> */}
      </ContainerSection>

      <ContactForm />

      {/* <div className={`w-full flex justify-center bg-gray-100`}>
            <div className={`flex flex-col sm:flex-row items-center justify-center md:w-full sm:flex-row-reverse`}>
                <div className="sm:w-1/2 flex flex-col items-center justify-center">
                    <div className="w-4/6 lg:w-4/6 sm:py-6 flex justify-center">
                        <img className={`h-64`} src={mapImage} />
                    </div>
                </div>
                <div className="w-full sm:w-1/2 sm:p-6 sm:pl-10 flex flex-col items-center justify-center">
                    <h3 className="text-4xl sm:text-5xl text-blue-700 font-bold text-center uppercase">¿Dónde estamos?</h3>
                    <br />
                    <p className="text-lg text-start text-blue-700">Contamos con oficinas ubicadas en varios lugares del mundo, con representantes exclusivos y profesionales certificados al rededor del mundo.</p>
                </div>
              </div>
        </div> */}

      <div className={`w-full flex justify-center bg-white`}>
        <div
          className={`flex sm:flex-row items-center justify-center md:w-full `}
        >
          {/* <div 
                className="hidden lg:block md:pt-6 md:mr-20 flex flex-row justify-end w-3/12"
              >
                  <img className={``} src={tipitosMundo} />
              </div> */}

          <div
            className="flex justify-center md:justify-start py-10"
            data-sal="fade-in"
            data-sal-delay="70"
            data-sal-duration="400"
            data-sal-easing="ease"
          >
            <img src={ubicaciones} />
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full bg-blue-100 py-5 border-t-2">
        <p className="text-gray-700 text-lg mx-5">Contamos con presencia profesional y una red de representantes oficiales en diversos países.</p>
      </div>
      
      <div className="w-full bg-white flex justify-center items-center pt-10">
        <div className="w-11/12 flex flex-col md:flex-row md:px-2 justify-center lg:justify-around items-start">
          <Location
            title={"Argentina"}
            mails={["info@feedback-it.com"]}
            tels={["541154373144"]}
            dirs={[
              "Av. Juan de Garay 3219, CABA",
              "Av. Julio A. Roca 1173, Hurlingham, Buenos Aires",
            ]}
          />

          <Location
            title={"Chile"}
            mails={["chile@feedback-it.com"]}
            dirs={["Arturo Prat 3060, San Miguel, Santiago"]}
          />

          <Location
            title={"México"}
            mails={["mexico@feedback-it.com"]}
            tels={["3341605468"]}
            dirs={[
              "Boulevard Pacífico #7630 PA3 B, Parque Industrial Pacífico, Tijuana",
            ]}
          />
        </div>
      </div>

      <div className="w-full bg-white flex justify-center items-center pb-10 sm:pt-5">
        <div className="w-11/12 flex flex-col md:flex-row md:px-2 justify-center lg:justify-around items-start">
          <Location
            title={"Perú"}
            mails={["peru@feedback-it.com"]}
            tels={["51994080932"]}
            dirs={["Pasaje Boterin 182, Urb Santa Catalina, La Victoria, Lima"]}
          />

          <Location
            title={"Paraguay"}
            mails={["paraguay@feedback-it.com"]}
            tels={["595981288892"]}
            dirs={["Cnel. Lirio 1718 c/ Juan de Garay, Asunción"]}
          />

          <Location
            title={"USA"}
            mails={["eeuu@feedback-it.com"]}
            tels={[
              "18777788287 (Costa Este)",
              "14076277176 (Orlando, Florida)",
            ]}
            dirs={["Suite 500, San Diego CA 92154"]}
          />
        </div>
      </div>


      {/* <div className="w-full flex justify-center py-10 bg-white">
          <div 
            data-sal="fade-in"
            data-sal-delay="100"
            data-sal-duration="700"
            data-sal-easing="ease">
            <img src={ubicaciones} />
          </div>
        </div>  */}

      {/* <div className="w-full flex justify-center overflow-hidden bg-gray-100 shadow-lg pb-10 ">
        <iframe 
          className="-mt-12 w-5/6 h-screen shadow-lg"
          style={{ filter: 'grayscale(0) contrast(1) opacity(0.8)' }} 
          src="https://www.google.com/maps/d/u/0/embed?mid=1IFDdeiO9JvJpYB8oWCb3npbWMcmI6-HU"/>
        {/* <iframe width="100%" height="100%" className="h-64" scrolling="no" src="https://www.google.com/maps/d/u/0/embed?mid=1IFDdeiO9JvJpYB8oWCb3npbWMcmI6-HU" style={{ filter: 'grayscale(0) contrast(1) opacity(0.5)' }} /> */}

      {/* </div>  */}
    </Layout>
  );
}

export default Contacto;
