import React from "react";

import PropTypes from "prop-types";

import location from "../images/location.svg";
import mailImage from "../images/mail2.svg";
import phone from "../images/phone2.svg";
import location2 from "../images/location2.svg";
import location3 from "../images/location3.svg";

const Location = ({ title, mails = null, tels = null, dirs = null }) => {
  return (
    <>
      {/* Ubicación */}
      <div className="flex flex-col items-start ml-10 mb-10 md:ml-0 md:mb-0 lg:w-3/12 md:mx-0 tooltip">
        {/* Titulo + logo */}
        <div className="flex flex-row items-end ">
          <div
            data-sal="flip-up"
            data-sal-delay="100"
            data-sal-duration="700"
            data-sal-easing="ease"
            className="h-10 mr-5 -ml-12"
          >
            {title == "Argentina" ? (
              <img src={location} className="h-10 w-8" />
            ) : (
              <img src={location3} className="h-10 w-8" />
            )}
          </div>
          <div className="flex items-center">

          <p className="font-black text-gray-900 text-xl uppercase">{title}</p>
          <span className={`tooltip-text p-3 ml-4 md:ml-4 rounded ${title=="Argentina" ? 'naranjafeedbackfondo' : 'azuloscurofondo'}`}>
            {title== "Argentina" ? 'Sede Central' : 'Representante Oficial'}
          </span>
          </div>
        </div>

        {/* Datos */}
        <div className="flex flex-col mt-1">
          {/* Mail */}
          {mails !== null &&
            mails.map((mail, index) => (
              <div key={index} className="flex flex-row items-start mt-1">
                <img src={mailImage} className="h-5 mr-2" />
                <a
                  className="text-gray-700 text-md"
                  href="mailto:info@feedback-it.com"
                >
                  {mail}
                </a>
              </div>
            ))}

          {/* Tel */}
          {tels !== null &&
            tels.map((tel, index) => (
              <div key={index} className="flex flex-row items-start mt-1">
                <img src={phone} className="h-5 mr-2" />
                <a className="text-gray-700 text-md" href={`tel:${tel}`}>
                  {tel}
                </a>
              </div>
            ))}

          {/* Direccion */}
          {dirs !== null &&
            dirs.map((dir, index) => (
              <div key={index} className="flex flex-row items-start mt-1">
                <img src={location2} className="h-5 mr-2" />
                <p className="text-gray-700 text-md" href="tel:#">
                  {dir}
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

Location.propTypes = {
  title: PropTypes.string.isRequired,
  mails: PropTypes.array,
  tels: PropTypes.array,
  dirs: PropTypes.array,
};

export default Location;
